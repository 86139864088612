import { CssBaseline } from '@mui/material'
import { Route, Routes } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { Roles } from '#api/types'

import Layout from './components/layout'
import RequireUser from './components/requireUser'
import AdminPage from './pages/admin.page'
import ApartmentPage from './pages/apartment/apartment.page'
import ApartmentsPage from './pages/apartments/apartments.page'
import HomePage from './pages/home.page'
import HousePage from './pages/house/house.page'
import LoginPage from './pages/login/login.page'
import NotesPage from './pages/notes/notes.page'
import ProfilePage from './pages/profile/profile.page'
import RegisterPage from './pages/register.page'
import UnauthorizePage from './pages/unauthorize.page'
import UsersPage from './pages/users/users.page'
import EmailVerificationPage from './pages/verifyemail.page'

const AllRoles = [Roles.EXPERT, Roles.DOCUMENTARIAN, Roles.ADMIN, Roles.SPECIALIST, Roles.DIRECTOR]

const App = () => (
  <>
    <CssBaseline />
    <ToastContainer />
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<HomePage />} />

        {/* Private Route */}
        <Route element={<RequireUser allowedRoles={AllRoles} />}>
          <Route path="profile" element={<ProfilePage />} />
        </Route>
        <Route element={<RequireUser allowedRoles={[Roles.ADMIN]} />}>
          <Route path="admin" element={<AdminPage />} />
        </Route>
        <Route element={<RequireUser allowedRoles={[Roles.ADMIN]} />}>
          <Route path="users" element={<UsersPage />} />
        </Route>

        <Route element={<RequireUser allowedRoles={[Roles.ADMIN, Roles.SPECIALIST]} />}>
          <Route path="notes" element={<NotesPage />} />
        </Route>

        <Route element={<RequireUser allowedRoles={AllRoles} />}>
          <Route path="house" element={<HousePage />} />
        </Route>

        <Route element={<RequireUser allowedRoles={AllRoles} />}>
          <Route path="apartments/:houseId" element={<ApartmentsPage />} />
        </Route>

        <Route element={<RequireUser allowedRoles={AllRoles} />}>
          <Route path="apartment/:houseId/:apartmentId" element={<ApartmentPage />} />
        </Route>

        <Route path="unauthorized" element={<UnauthorizePage />} />
      </Route>

      <Route path="verifyemail" element={<EmailVerificationPage />}>
        <Route path=":verificationCode" element={<EmailVerificationPage />} />
      </Route>
      <Route path="login" element={<LoginPage />} />
      <Route path="register" element={<RegisterPage />} />
    </Routes>
  </>
)

export default App
