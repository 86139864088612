import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import Step from '@mui/material/Step'
import StepContent from '@mui/material/StepContent'
import StepLabel from '@mui/material/StepLabel'
import Stepper from '@mui/material/Stepper'
import Typography from '@mui/material/Typography'
import { useState } from 'react'
import { toast } from 'react-toastify'

import { useLinkingTelegramMutation, useResetTelegramIdMutation } from '#api/auth-api'

import { useTelegram } from '../../hooks'
import { StepOne, TreeStep, TwooStep } from './steps'

const ProfileSyncTelegram = () => {
  const [resetTelegram] = useResetTelegramIdMutation()
  const [getLinkingTelegram] = useLinkingTelegramMutation()
  const [activeStep, setActiveStep] = useState(0)
  const { telegramId, setTelegramId } = useTelegram()

  const handlerTwoStep = async () => {
    const result = await getLinkingTelegram(null)
    const id = (result as any)?.data?.id

    if (!id) {
      return
    }

    setTelegramId(id as string)
    handleNext()
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleReset = () => {
    setActiveStep(0)
  }

  const resetTelegramId = async () => {
    const res = await resetTelegram(null)

    if ((res as any)?.data?.isReset) {
      toast.success('Вы успешно отвязали бота от сервиса')
      setTelegramId(null)
    }
  }

  const steps = [
    {
      label: 'Перейдите в телеграмм бот',
      description: <StepOne />
    },
    {
      label: 'Синхронизация',
      description: <TwooStep handler={handlerTwoStep} />
    },
    {
      label: 'Финиш',
      description: <TreeStep telegramId={telegramId} />
    }
  ]

  return (
    <Box>
      <Typography sx={{ mb: 2 }} variant="h6">
        Синхронизация с телеграмм ботом
        {telegramId && (
          <Button onClick={resetTelegramId} variant="contained" sx={{ ml: 1 }} color="error">
            Сбросить
          </Button>
        )}
      </Typography>

      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel
              optional={index === steps.length - 1 ? <Typography variant="caption">Последний шаг</Typography> : null}
            >
              {step.label}
            </StepLabel>
            <StepContent>
              <Typography>{step.description}</Typography>

              <Box sx={{ mt: 2, mb: 2 }}>
                <Button variant="contained" onClick={handleNext} sx={{ mt: 1, mr: 1 }}>
                  {index === steps.length - 1 ? 'Завершить' : 'Далее'}
                </Button>
                <Button disabled={index === 0} onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                  Назад
                </Button>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0} sx={{ p: 3 }}>
          <Typography>Все шаги пройденны - вы закончили</Typography>
          <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
            Пройти заново
          </Button>
        </Paper>
      )}
    </Box>
  )
}

export { ProfileSyncTelegram }
