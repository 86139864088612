import { Link, TextField } from '@mui/material'

import { useRightCurrentUser } from '#hooks/use-right-current-user'

const StepOne = () => {
  const { user } = useRightCurrentUser()
  const email = btoa(unescape(encodeURIComponent(user?.email ?? '')))
  const hash = `${email}${user?.id}9b447fac060f5786047d332faadd1d2470ebc702`

  return (
    <>
      Перейдите по ссылке в телеграмм бот и отправьте указанный текст
      <Link sx={{ display: 'block', mt: 2, mb: 2 }} target="_blank" href={`https://t.me/avitek_crm_bot?start`}>
        Avitek CRM bot
      </Link>
      <TextField size="small" value={hash} sx={{ width: '700px' }} variant="filled" />
    </>
  )
}

export { StepOne }
